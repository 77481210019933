<template>
	<div class="q-gutter-md">
		<q-fab
			outline
			rounded
			label-position="left"
			color="primary"
			icon="keyboard_arrow_up"
			:label="$q.screen.gt.sm ? 'Actions' : ''"
			direction="up"
		>
			<q-fab-action
				icon="o_close"
				:label="$q.screen.gt.sm ? 'Cancel' : ''"
				color="primary"
				:to="{ name: 'Dashboard' }"
				@click='set_active_item(1)'
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Cancel
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				v-if="logged_user.customer_permission !== 'SENDER'"
				icon="save"
				:label="$q.screen.gt.sm ? 'Save' : ''"
				color="primary"
				@click="saveScenario(false, false)"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Save
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				v-if="!current_CG.customer_group.sandbox && logged_user.customer_permission !== 'SENDER'"
				icon="o_event"
				:label="$q.screen.gt.sm ? 'Schedule' : ''"
				color="primary"
				@click="displayScheduleModal"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Schedule
				</q-tooltip>
			</q-fab-action>
		</q-fab>

		<q-btn
			v-if="!current_CG.customer_group.sandbox"
			size="18px"
			round
			icon="send"
			color="primary"

			:disabled="is_session_init_running"
			@click="displayModals"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Continue
			</q-tooltip>
		</q-btn>

		<q-dialog v-model="show_audio_noticeboard_modal">
			<AudioNoticeboardModal
				@close="show_audio_noticeboard_modal=false"
				@confirm="confirm"
			/>
		</q-dialog>
		<q-dialog v-model="show_recipient_count">
			<RecipientCountModal
				:number_recipients="number_recipients"
				@close="show_recipient_count=false"
				@save-and-send="saveAndSend"
				@send-only="sendScenario"
			/>
		</q-dialog>
		<q-dialog v-model="show_schedule_modal">
			<ScheduleModal
				:active_tab="active_tab"
				:session_id="null"
				:scenario_name="scenario_name"
				:recurrent_session_id="null"
				:preferred_timezone="logged_user.preferred_timezone"
				:show_recurrent_message_fields='true'
				@schedule-message="scheduleScenario"
				@update-recurrent-data="updateRecurrentData"
				@update-is-recurrent='updateIsRecurrent'
				@close="show_schedule_modal=false"
				@update-values="updateValues"
			/>
		</q-dialog>
		<q-dialog v-model="has_messages">
			<CheckDatesMessage
				:messages="messages"
				@close="closeMessages()"
			/>
		</q-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AudioNoticeboardModal from '@/components/message_forms/finish_tab/AudioNoticeboardModal.vue';
import RecipientCountModal from '@/components/message_forms/finish_tab/RecipientCountModal.vue';
import MessageFormsAPI from '@/services/api/MessageForm.js';
import ScheduleModal from '@/components/scenarios/calendar_reports/ScheduleModal.vue';
import Utils from '@/services/utils.js';
import CheckDatesMessage from '@/components/scenarios/calendar_reports/CheckDatesMessage.vue';
export default {
	name: 'FinishTabButtons',
	components: {
		AudioNoticeboardModal,
		RecipientCountModal,
		ScheduleModal,
		CheckDatesMessage
	},
	props: {
		final_recipients: Object,
		previews: Object
	},
	data() {
		return {
			has_noticeboard_numbers: false,
			show_noticeboard: false,
			show_audio_noticeboard_modal: false,
			show_recipient_count: false,
			number_recipients: 0,
			two_way_sms_link_permission: false,
			check_session_init_interval: null,
			show_schedule_modal: false,
			active_tab: "",
			scheduled_datetime: {},
			recurrent_data: {},
			is_recurrent: false,
			check_holiday: false,
			reschedule_holiday: false,
			check_weekend: false,
			reschedule_weekend: false,
			selected_country: '',
			selected_subdivision: '',
			holiday_list: [],
			messages: [],
			has_messages: false

		};
	},
	computed: {
		...mapGetters([
			'logged_user',
			'current_CG',
			'use_noticeboard',
			'select_all_devices',
			'email_subject_line',
			'scenario_type',
			'use_conference_recall',
			'scenario_test',
			'is_dashboard',
			'is_phone_invoke',
			'is_incident_manager',
			'is_email_invoke',
			'messages_content',
			'global_message',
			'conference_bridge_intro',
			'amd_conference',
			'sms_two_way_link',
			'devices_message_content',
			'tts_voice',
			'use_accounts_default_tts_voice',
			'email_sms_attachment_files',
			'email_attachment_files',
			'include_links_in_email',
			'send_quick_report',
			'quick_report_recipients',
			'use_default_answering_services_content',
			'scenario_name',
			'scenario_message_id',
			'scenario',
			'polling_value',
			'polling_options',
			'auto_retry',
			'auto_retry_minutes',
			'polling_timeout',
			'selected_language',
			'noticeboard_message',
			'default_noticeboard_message_voice',
			'noticeboard_language',
			'noticeboard_number',
			'message_text',
			'sequence_time',
			'include_links_in_sms',
			'sms_attachment_files',
			'is_session_init_running',
			'errors',
			'tabs_with_errors',
			'polling_options_voice',
			'trigger_scenario',
			'trigger_time',
			'triggered_scenario',
			'is_subset_scenario',
			'use_microsoft_teams',
			'use_slack',
			'use_pwa',
			'selected_teams_webhooks',
			'selected_slack_webhooks',
			'message_content_teams',
			'message_content_slack',
			'include_links_in_teams',
			'include_links_in_slack',
			'teams_attachment_files',
			'slack_attachment_files',
			'voice_channel_active'
		]),
	},
	methods: {
		...mapActions(['updateIsSessionInitRunning', 'updateMessage', 'updateErrors', 'set_active_item']),
		createFinalData(run, schedule) {
			let scenario_data = {};
			scenario_data['select_all_devices'] = this.select_all_devices;
			scenario_data['email_subject_line'] = this.email_subject_line;
			scenario_data['scenario_type'] = this.scenario_type;
			scenario_data['real'] = !this.scenario_test;
			scenario_data['is_subset'] = this.is_subset_scenario;
			let flags = {};
			flags['is_dashboard'] = this.is_dashboard;
			flags['is_phone_invoke'] = this.is_phone_invoke;
			flags['is_incident_manager'] = this.is_incident_manager;
			flags['is_email_invoke'] = this.is_email_invoke;
			scenario_data['flags'] = flags
			scenario_data['recall_participants'] = this.use_conference_recall;

			if (this.messages_content) {
				scenario_data['global_message_content'] = this.global_message;
				scenario_data['global_message'] = true;
			} else {
				scenario_data['global_message'] = false;
			}

			if (scenario_data['scenario_type'] == 'conference') {
				scenario_data['global_message'] = false;
				if (!this.conference_bridge_intro) {
					scenario_data['bridge_intro_live'] = 'custom_conference_live';
				} else {
					scenario_data['bridge_intro_live'] = 'default_conference_live';
				}
				if (!this.amd_conference) {
					scenario_data['bridge_intro_amd'] = 'custom_conference_amd';
				} else {
					scenario_data['bridge_intro_amd'] = 'default_conference_amd';
				}
			}

			if (this.scenario_type == 'sequence') {
				scenario_data['sequence'] = [];
				let copy_of_devices = JSON.parse(JSON.stringify(this.scenario.devices));
				copy_of_devices = Object.keys(copy_of_devices).map(function (key) {
					return copy_of_devices[key];
				});
				copy_of_devices.sort(function (obj1, obj2) {

					return obj1.position - obj2.position;
				});
				for (var device in copy_of_devices) {
					if (copy_of_devices[device] != undefined) {
						scenario_data['sequence'].push({
							"id": copy_of_devices[device]['field_id'],
							"type": copy_of_devices[device]['dispatch_type'],
							"position": copy_of_devices[device]['position']
						});
					}
				}

				scenario_data['sequence_time'] = this.sequence_time * 60;
			}

			if (this.two_way_sms_link_permission) {
				scenario_data['sms_two_way_link'] = this.sms_two_way_link;
			}

			if (this.trigger_scenario) {
				let timezone = 'Europe/London';
				if (this.logged_user.preferred_timezone) {
					timezone = this.logged_user.preferred_timezone;
				}
				scenario_data['trigger_scenario'] = {
					'triggered_scenario': this.triggered_scenario.id,
					'trigger_after_minutes': this.trigger_time,
					'timezone': timezone
				}
			}


			// NOT SURE IF WE NEED THIS
			// for (var item in $scope.scenario.devices){
			// 	if ($scope.scenario.devices[item] == false){
			// 		delete $scope.scenario.devices[item];
			// 	}
			// }
			let message_forms_for_saving = [];
			for (let item in this.devices_message_content) {
				message_forms_for_saving[item] = {};
				if (this.scenario_type === 'conference') {
					if (this.conference_bridge_intro) {
						message_forms_for_saving[item]['message'] = this.customer_group_settings.conference.default_message_live;
					} else {
						message_forms_for_saving[item]['message'] = this.global_message;
					}
					if (this.amd_conference) {
						message_forms_for_saving[item]['message_amd'] = this.customer_group_settings.conference.default_message_amd;
					} else {
						message_forms_for_saving[item]['message_amd'] = this.message_text;
					}
				} else {
					if (!this.messages_content) {
						message_forms_for_saving[item]['message'] = this.devices_message_content[item].text_value;
					} else {
						message_forms_for_saving[item]['message'] = this.global_message;
					}
				}
				if (this.devices_message_content[item].dispatch_type === 'VOICE') {
					message_forms_for_saving[item]['dispatch_type'] = 'VOICE';
					message_forms_for_saving[item]['heading'] = 'Voice Call';
					if (this.scenario_type === 'conference') {
						message_forms_for_saving[item]['tts_voice'] = this.$store.getters.tts_voice.name;
					} else {
						message_forms_for_saving[item]['is_dataset_default_voice_used'] = this.use_accounts_default_tts_voice
						if (!this.tts_voice || !Object.keys(this.tts_voice).length) {
							if (this.customer_group_settings['default_tts_voice'] === '' || this.customer_group_settings['default_tts_voice'] == undefined) {
								message_forms_for_saving[item]['tts_voice'] = 'Amy'
							} else {
								message_forms_for_saving[item]['tts_voice'] = this.customer_group_settings['default_tts_voice']['name'];
							}
						} else {
							message_forms_for_saving[item]['tts_voice'] = this.tts_voice.name;
						}
					}
					message_forms_for_saving[item]['message_type'] = 'tts';

					if (this.customer_group_settings.default_amd_message && this.scenario_type !== 'conference') {
						scenario_data['default_amd_message'] = true;
						if (this.customer_group_settings['default_amd_message']['message_type'] == 'recording_amd') {
							message_forms_for_saving[item]['message_amd_type'] = 'url';
							message_forms_for_saving[item]['amd_file_source'] = this.customer_group_settings['default_amd_message']['source'];
							if (message_forms_for_saving[item]['amd_file_source'] == 'local_device') {
								message_forms_for_saving[item]['message_amd'] = this.customer_group_settings['default_amd_message']['message']['url'];
							} else {
								message_forms_for_saving[item]['message_amd'] = this.customer_group_settings['default_amd_message']['message'];
							}
						} else {
							message_forms_for_saving[item]['message_amd_type'] = 'tts_amd';
							if (!this.customer_group_settings['default_amd_message'] || this.customer_group_settings['default_amd_message']['message'] === '') {
								message_forms_for_saving[item]['message_amd'] = message_forms_for_saving[item]['message'];
							} else {
								message_forms_for_saving[item]['message_amd'] = this.customer_group_settings['default_amd_message']['message'];
							}
						}
					} else if (!this.customer_group_settings.default_amd_message) {
						scenario_data['default_amd_message'] = false;
					}
					message_forms_for_saving[item]['message_amd_type'] = 'tts_amd';
					if (this.message_text) {
						message_forms_for_saving[item]['message_amd'] = this.message_text;
					}
					message_forms_for_saving[item]['message_preview_live'] = this.previews['voice_preview_live'];
					message_forms_for_saving[item]['message_preview_amd'] = this.previews['voice_preview_amd'];
				}
				if (message_forms_for_saving[item]['message_amd'] == message_forms_for_saving[item]['message']) {
					message_forms_for_saving[item]['same_message'] = true;
				}

				// if email attachment is set
				if (this.devices_message_content[item].dispatch_type == 'EMAIL') {
					message_forms_for_saving[item]['dispatch_type'] = 'EMAIL';
					message_forms_for_saving[item]['heading'] = 'Email';
					message_forms_for_saving[item]['message_preview'] = this.previews['email_preview'];
					if (this.messages_content) {
						if (this.include_links_in_email) {
							message_forms_for_saving[item]['email_attachments'] = this.convertAttachmentsSettingFormat(this.email_sms_attachment_files, 'EMAIL');
						} else {
							message_forms_for_saving[item]['email_attachments'] = null;
						}
					} else if (this.email_attachment_files != undefined && this.email_attachment_files.length != 0) {
						if (this.include_links_in_email) {
							message_forms_for_saving[item]['email_attachments'] = this.email_attachment_files;
						}
					} else {
						message_forms_for_saving[item]['email_attachments'] = null;
					}
				}
				if (this.devices_message_content[item].dispatch_type == 'SMS') {
					message_forms_for_saving[item]['dispatch_type'] = 'SMS';
					message_forms_for_saving[item]['heading'] = 'SMS';
					message_forms_for_saving[item].message_preview = Utils.parseGSM(this.previews.sms_preview);

					if (this.$store.getters.global_message) {
						message_forms_for_saving[item].message = Utils.parseGSM(this.$store.getters.global_message)
					} else {
						message_forms_for_saving[item].message = Utils.parseGSM(this.devices_message_content[item].text_value)
					}

					if (this.current_CG.root_customer_group_permissions.includes('Merge fields')) {
						message_forms_for_saving[item].message_preview_approximate_length = Utils.approximateMessageContent(this.previews.sms_preview).length;
					}

					if (this.messages_content) {
						if (this.include_links_in_sms) {
							message_forms_for_saving[item]['sms_attachment_files'] = this.convertAttachmentsSettingFormat(this.email_sms_attachment_files, 'SMS');
						} else {
							message_forms_for_saving[item]['sms_attachment_files'] = [];
						}
					} else if (this.sms_attachment_files != undefined) {
						if (this.include_links_in_sms) {
							message_forms_for_saving[item]['sms_attachment_files'] = this.convertAttachmentsSettingFormat(this.sms_attachment_files, 'SMS');
						}
					}
				}
			}

			if (this.send_quick_report) {
				scenario_data['send_quick_report'] = true;
				if (this.quick_report_recipients.length) {
					scenario_data['quick_report_recipients'] =
						{
							type: this.$store.getters.quick_report_recipients_type.type,
							values: this.quick_report_recipients
						}
					if (this.$store.getters.quick_report_recipients_type.type === 'field') {
						scenario_data['quick_report_recipients']['field'] = this.$store.getters.quick_report_recipients_type.id
						scenario_data['quick_report_recipients']['field_type'] = this.$store.getters.quick_report_recipients_type.field_type
					}
				} else {
					scenario_data['quick_report_recipients'] = {
						['type']: '',
						['values']: []
					}
					if (this.$store.getters.quick_report_recipients_type) {
						scenario_data['quick_report_recipients']['type'] = this.$store.getters.quick_report_recipients_type.type
					}
				}
			} else {
				scenario_data['send_quick_report'] = false;
				scenario_data['quick_report_recipients'] = null;
			}

			if (this.use_default_answering_services_content) {
				scenario_data['separate_call'] = true;
			}

			scenario_data['name'] = this.scenario_name;

			if (this.scenario_message_id) {
				scenario_data['message_id'] = this.scenario_message_id;
			} else {
				scenario_data['message_id'] = null;
			}

			scenario_data['devices'] = this.scenario.devices;
			scenario_data['message_forms'] = message_forms_for_saving;
			if (!this.voice_channel_active) {
				scenario_data['auto_retry'] = false
				scenario_data['auto_retry_minutes'] = null;
			} else {
				scenario_data['auto_retry'] = this.auto_retry;
				if (this.auto_retry) {
					scenario_data['auto_retry_minutes'] = this.auto_retry_minutes;
				}
			}

			if (this.polling_value) {
				scenario_data['polling_options_voice'] = this.polling_options_voice;
				scenario_data['polling'] = this.polling_options;

				for (let options of scenario_data['polling']) {
					if (options.option_text) {
						options.option_text = options.option_text.trim()
					}
				}

				if (!Object.keys(this.polling_timeout).length) {
					scenario_data['polling_timeout'] = {"value": "1800", "text": "30 minutes"}
				} else {
					scenario_data['polling_timeout'] = this.polling_timeout;
				}

			}
			if (this.selected_language != undefined) {
				scenario_data['selected_language'] = this.selected_language;
			}

			if (this.use_noticeboard) {
				let noticeboard = {};
				noticeboard['settings'] = {};
				noticeboard['settings']['message_type'] = 'noticeboard_tts';
				noticeboard['message'] = this.noticeboard_message;
				noticeboard['message_type'] = 'noticeboard_tts';
				if (!this.default_noticeboard_message_voice) {
					if (this.noticeboard_language != undefined) {

						noticeboard['settings']['tts_voice'] = this.noticeboard_language['name'];
						noticeboard['noticeboard_tts_voice'] = this.noticeboard_language['name'];
					} else {
						noticeboard['settings']['tts_voice'] = null;
						noticeboard['noticeboard_tts_voice'] = null;
					}
				} else {
					noticeboard['settings']['tts_voice'] = 'Amy';
					noticeboard['noticeboard_tts_voice'] = 'Amy';
				}
				if (Object.keys(this.noticeboard_number).length) {
					noticeboard['number'] = this.noticeboard_number;
				}
				scenario_data['noticeboard'] = noticeboard;
			}
			scenario_data.recipients = this.final_recipients;
			scenario_data['run'] = run;
			scenario_data['schedule'] = schedule;
			if (schedule) {
				scenario_data['scheduled_datetime'] = this.scheduled_datetime;
			}
			if (schedule && this.is_recurrent) {
				scenario_data['recurrent'] = true;
				scenario_data['recurrent_data'] = this.recurrent_data;
			}

			scenario_data['check_holiday'] = this.check_holiday
			scenario_data['reschedule_holiday'] = this.reschedule_holiday
			scenario_data['check_weekend'] = this.check_weekend
			scenario_data['reschedule_weekend'] = this.reschedule_weekend
			scenario_data['selected_country'] = this.selected_country
			scenario_data['selected_subdivision'] = this.selected_subdivision
			scenario_data['ignore_holiday_list'] = this.holiday_list

			let inc = scenario_data.recipients.include_criteria
			for (let i in inc) {
				if (Object.keys(inc[i]).length) {
					delete inc[i].recipient_type.options;
				}
			}
			let exc = scenario_data.recipients.exclude_criteria
			for (let i in exc) {
				if (Object.keys(exc[i]).length) {
					delete exc[i].recipient_type.options;
				}
			}

			if (this.use_microsoft_teams) {
				let webhooks_ids = Object.keys(this.selected_teams_webhooks).map(k => this.selected_teams_webhooks[k].id)
				scenario_data['microsoft_teams'] = {
					'use_microsoft_teams': this.use_microsoft_teams,
					'selected_webhooks': webhooks_ids,
				}
				if (this.$store.getters.global_message) {
					scenario_data['microsoft_teams']['message_content_teams'] = this.$store.getters.global_message
					if (this.include_links_in_teams) {
						scenario_data['microsoft_teams']['attachments'] =
							this.convertAttachmentsSettingFormat(this.email_sms_attachment_files, 'TEAMS');
					}
				} else {
					scenario_data['microsoft_teams']['message_content_teams'] = this.message_content_teams
					if (this.include_links_in_teams) {
						scenario_data['microsoft_teams']['attachments'] =
							this.convertAttachmentsSettingFormat(this.teams_attachment_files, 'TEAMS');
					}
				}
			}
			if (this.use_slack) {
				let webhooks_ids = Object.keys(this.selected_slack_webhooks).map(k => this.selected_slack_webhooks[k].id)
				scenario_data['slack'] = {
					'use_slack': this.use_slack,
					'selected_webhooks': webhooks_ids,
				}
				if (this.$store.getters.global_message) {
					scenario_data['slack']['message_content_slack'] = this.$store.getters.global_message
					if (this.include_links_in_slack) {
						scenario_data['slack']['attachments'] =
							this.convertAttachmentsSettingFormat(this.email_sms_attachment_files, 'SLACK');
					}
				} else {
					scenario_data['slack']['message_content_slack'] = this.message_content_slack
					if (this.include_links_in_slack) {
						scenario_data['slack']['attachments'] =
							this.convertAttachmentsSettingFormat(this.slack_attachment_files, 'SLACK');
					}
				}
			}
			if (this.use_pwa) {
				scenario_data.use_pwa = this.use_pwa
			}
			return scenario_data;
		},
		async checkSessionInitResult(task_id, is_schedule) {
			try {
				const response = await MessageFormsAPI.getRunResult(task_id);
				if ('session_id' in response || 'success' in response) {
					clearInterval(this.check_session_init_interval);
					this.updateIsSessionInitRunning(false);
					if (response['messages']) {
						this.has_messages = true
						this.messages = response['messages']
					} else {
						this.has_messages = false
						let message = "";
						if (is_schedule) {
							message = "Your message has been successfully scheduled";
						} else {
							message = "Your message has been successfully initialised, you will be redirected to the report";
						}
						this.updateMessage({'type': 'success', 'text': message});

						setTimeout(() => {
							this.updateMessage({});
							if (is_schedule) {
								this.set_active_item(204);
								this.$router.push({name: 'Message Calendar'});
							} else {
								this.set_active_item(205);
								this.$router.push({
									name: 'Dispatch Overview',
									params: {id: response.session_id}
								});
							}
						}, 2000);
					}
				}
			} catch (error) {
				clearInterval(this.check_session_init_interval);
				this.updateIsSessionInitRunning(false);
				setTimeout(() => { this.check_session_init_interval = null; }, 500);

				if (typeof (error.data) == 'string') {
					this.updateMessage({'type': 'error', 'text': error.data});
				} else {
					let error_message = "";
					for (let key in error.data) {
						error_message += error.data[key];
					}
					this.updateMessage({'type': 'error', 'text': error_message});
				}
			}
		},
		convertAttachmentsSettingFormat(attachments, dispatch_type) {
			let file_attachments = [];
			if (dispatch_type === 'SMS') {
				for (let attachment of attachments) {
					let obj = {};
					obj = {
						"name": attachment.attachment_name,
						"key": attachment.attachment_key,
						"source": attachment.attachment_source
					};
					if (obj.source === 'media_library') {
						obj.id = attachment.id;
						obj.customer_group = attachment.customer_group;
					}
					if (attachment.attachment_source === 'local_device') {
						obj.attachment_size = attachment.attachment_size;
					}
					file_attachments.push(obj);
				}
			}

			if (dispatch_type === 'EMAIL') {
				for (let attachment of attachments) {
					let obj = {};
					obj = {
						"attachment_name": attachment.attachment_name,
						"attachment_key": attachment.attachment_key,
						"attachment_source": attachment.attachment_source
					};
					if (obj.attachment_source === 'media_library') {
						obj.id = attachment.id;
						obj.customer_group = attachment.customer_group;
					}
					if (obj.attachment_source === 'local_device') {
						obj.attachment_size = attachment.attachment_size;
					}

					file_attachments.push(obj);
				}
			}
			if (dispatch_type === 'TEAMS' || dispatch_type === 'SLACK') {
				for (let attachment of attachments) {
					let obj = {};
					obj = {
						"attachment_name": attachment.attachment_name,
						"attachment_key": attachment.attachment_key,
						"attachment_source": attachment.attachment_source
					};
					if (obj.attachment_source === 'media_library') {
						obj.id = attachment.id;
						obj.customer_group = attachment.customer_group;
					}
					if (obj.attachment_source === 'local_device') {
						obj.attachment_size = attachment.attachment_size;
					}

					file_attachments.push(obj);
				}
			}
			return file_attachments;
		},
		async saveScenario(run, schedule) {
			this.updateMessage({});
			let scenario_data = this.createFinalData(run, schedule);
			let response = {};
			try {
				if (this.$route.params.id) {
					response = await MessageFormsAPI.saveExistingScenario(this.$route.params.id, scenario_data);
				} else {
					response = await MessageFormsAPI.saveScenario(scenario_data);
				}
				if (!run) {
					let message = '';
					message = 'Your message has been saved successfully';
					this.updateMessage({type: 'success', text: message})
					setTimeout(() => {
						this.updateMessage({});
						this.$router.push({name: 'Send Message'});
						this.set_active_item(202);
					}, 2000);
				}
				if (schedule || run) {
					this.show_recipient_count = false;
					this.updateIsSessionInitRunning(true);
					this.check_session_init_interval = setInterval(() => {
						this.checkSessionInitResult(response.task_id, schedule);
					}, 1000);
				}
			} catch (error) {
				this.processError(error);
			}
		},
		async sendScenario() {
			let scenario_data = this.createFinalData(true, false);
			let response = {};
			try {
				if (!this.$route.params.id) {
					response = await MessageFormsAPI.sendScenarioWithoutSaving(scenario_data);
				} else {
					response = await MessageFormsAPI.sendExistingScenarioWithoutSaving(this.$route.params.id, scenario_data);
				}
				this.updateIsSessionInitRunning(true);
				this.show_recipient_count = false;
				this.check_session_init_interval = setInterval(() => {
					this.checkSessionInitResult(response.task_id, false);
				}, 1000);
			} catch (error) {
				this.processError(error);
			}
		},
		saveAndSend() {
			this.saveScenario(true, false);
		},
		scheduleScenario(scheduled_datetime) {
			this.show_schedule_modal = false;
			this.scheduled_datetime = scheduled_datetime;
			this.saveScenario(true, true);
		},
		updateRecurrentData(obj) {
			this.recurrent_data = obj;
		},
		updateIsRecurrent(val) {
			this.is_recurrent = val;
		},
		displayScheduleModal() {
			if (this.$store.getters.scenario_test) {
				this.active_tab = "TEST";
			} else {
				this.active_tab = "REAL";
			}
			this.show_schedule_modal = true;
		},
		updateValues({type, value}) {
			this[type] = value
		},
		closeMessages() {
			this.has_messages = false
			setTimeout(() => {
				this.set_active_item(204);
				this.$router.push({name: 'Message Calendar'});
			}, 500);
		},
		async displayModals() {
			if (this.show_noticeboard && !this.use_noticeboard) {
				this.show_audio_noticeboard_modal = true;
			} else {
				this.getRecipientsCount();
			}
		},
		async confirm() {
			this.show_audio_noticeboard_modal = false;
			this.getRecipientsCount();
		},
		async getRecipientsCount() {
			try {
				let recipients = JSON.parse(JSON.stringify(this.final_recipients))
				let inc = recipients.include_criteria
				for (let i in inc) {
					if (Object.keys(inc[i]).length) {
						delete inc[i].recipient_type.options;
					}
				}
				let exc = recipients.exclude_criteria
				for (let i in exc) {
					if (Object.keys(exc[i]).length) {
						delete exc[i].recipient_type.options;
					}
				}
				this.show_recipient_count = true;
				this.number_recipients = -1;
				this.number_recipients = await MessageFormsAPI.getRecipientsCount(recipients);
			} catch (error) {
				this.number_recipients = 0;
				this.processError(error);
			}
		},
		processError(error) {
			this.updateErrors(error.data);
			const error_tabs = this.tabs_with_errors.join(', ').toUpperCase();
			let text = error.data;
			if (error_tabs) {
				text = `There are errors in the following tabs: ${error_tabs}. Please correct them.`;
			}
			this.updateMessage({type: 'error', text});
		}
	},
	created() {
		if (this.current_CG.customer_group.settings == null || this.current_CG.customer_group.settings == undefined) {
			this.customer_group_settings = {};
		} else {
			this.customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
		}

		this.has_noticeboard_numbers = !(this.customer_group_settings['noticeboard_numbers'] == undefined || this.customer_group_settings['noticeboard_numbers'] == "");
		this.show_noticeboard = (this.current_CG.root_customer_group_permissions.includes('Audio noticeboard') && this.has_noticeboard_numbers);
		this.two_way_sms_link_permission = this.current_CG.root_customer_group_permissions.includes('SMS links for 2 way messages');
		this.has_phone_invoke_permission = this.current_CG.root_customer_group_permissions.includes('Phone invoke');
	}
}
</script>
